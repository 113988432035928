import React, { useEffect } from "react";
import tick from '../assets/tick.png';

const Complete = () => {
  useEffect(() => {
    // Replace the history state to prevent going back
    window.history.replaceState(null, '', window.location.href);
  }, []);

  return (
    <div style={styles.container}>
      <img src={tick} alt="Success Tick" style={styles.image} />
      <p style={styles.heading}>Process Successfully Captured</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#F2F7FF',
  },
  image: {
    width: '100px',
    height: '100px',
  },
  heading: {
    fontSize: '24px',
    color: '#00796b',
  },
};

export default Complete;
