import React, { useEffect, useState } from "react";
import axios from "axios";
import arvoloader from "../assets/arvo.gif";
import imageCompression from 'browser-image-compression';
import { useNavigate } from "react-router-dom";

function QRReader() {
  const [title, setTitle] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [skuid, setSkuid] = useState("");
  const [index, setIndex] = useState("");
  const [loading, setLoading] = useState(true);
  const [journeyNumber, setJourneyNumber] = useState(null);
  const [batchJourneyNumber, setBatchJourneyNumber] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const urlPath = window.location.pathname;
    const skuidWithIndex = decodeURIComponent(urlPath.split("/").pop());
    const [skuidParam, indexParam] = skuidWithIndex.split("×");

    if (skuidParam && indexParam) {
      setSkuid(skuidParam);
      setIndex(indexParam);
      setJourneyNumber(parseInt(indexParam));
      fetchProductJourney(skuidParam, indexParam);
    } else {
      setTitle("Invalid SKU ID or index.");
      setLoading(false);
    }
  }, []);

  const fetchProductJourney = async (skuidParam, indexParam) => {
    try {
      const response = await axios.get(
        `https://1c6see68bj.execute-api.ap-south-1.amazonaws.com/prod/product-journey?skuid=${skuidParam}`
      );

      console.log('API Response:', response.data);

      if (response.data && response.data.ProductJourney && response.data.ProductJourney.length > 0) {
        const journey = response.data.ProductJourney.find(item => item.index === parseInt(indexParam));
        console.log('Found Journey:', journey);
        if (journey) {
          setTitle(journey.title);
          fetchBatchNumber(skuidParam, journey.index);
        } else {
          setTitle("No product journey title found.");
        }
      } else {
        setTitle("No product journey found.");
      }
    } catch (error) {
      console.error("Error fetching product journey:", error);
      setTitle("Error fetching product journey.");
    } finally {
      setLoading(false);
    }
  };

  const fetchBatchNumber = async (skuid, journeyNumber) => {
    try {
      const response = await axios.get(
        `https://f9qv0gdcpc.execute-api.ap-south-1.amazonaws.com/prod/traceability?skuid=${skuid}`
      );

      console.log('Batch API Response:', response.data);

      if (response.data && response.data.length > 0) {
        let latestBatch = response.data[0].BatchNumber;
        let createNewBatch = true;

        for (const entry of response.data) {
          if (entry.JourneyNumber < journeyNumber && entry.Status !== 'Completed') {
            latestBatch = entry.BatchNumber;
            createNewBatch = false;
            break;
          }
        }

        if (createNewBatch) {
          const latestEntry = response.data[response.data.length - 1];
          latestBatch = incrementBatchNumber(latestEntry.BatchNumber);
        }

        setBatchNumber(latestBatch);

        const batchJourney = response.data.find(entry => entry.BatchNumber === latestBatch);
        if (batchJourney) {
          setBatchJourneyNumber(batchJourney.JourneyNumber);
        }
      }
    } catch (error) {
      console.error("Error fetching batch number:", error);
    }
  };

  const incrementBatchNumber = (batchNumber) => {
    const match = batchNumber.match(/\d+$/);
    if (match) {
      const numericPart = parseInt(match[0]);
      const incrementedNumeric = String(numericPart + 1);
      return batchNumber.replace(/\d+$/, incrementedNumeric);
    }
    return batchNumber + '1';
  };

  const handleSubmit = async () => {
    if (journeyNumber !== 1 && batchJourneyNumber !== journeyNumber - 1) {
      alert("Please complete the previous process.");
      return;
    }

    try {
      const response = await axios.post(
        `https://f9qv0gdcpc.execute-api.ap-south-1.amazonaws.com/prod/traceability`,
        {
          skuid,
          processNumber: index,
          imageBase64: capturedImage ? capturedImage.split(',')[1] : null
        }
      );

      if (response.data && response.data.message) {
        alert(response.data.message);
        if (response.data.message === "Product journey saved successfully") {
          navigate("/complete", { replace: true });
        }
      } else {
        alert("Error saving product journey.");
      }
    } catch (error) {
      console.error("Error saving product journey:", error);
      alert("Error saving product journey.");
    }
  };

  const handleButtonClick = () => {
    const userConfirmed = window.confirm("Are you sure you want to Capture this Process?");
    if (userConfirmed) {
      handleSubmit();
    }
  };

  const handleCapture = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true
      });
      const compressedReader = new FileReader();
      compressedReader.onloadend = () => {
        setCapturedImage(compressedReader.result);
      };
      compressedReader.readAsDataURL(compressedFile);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRecapture = () => {
    setCapturedImage(null);
  };

  const handleRemove = () => {
    setCapturedImage(null);
  };

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      {loading && (
        <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000
        }}>
          <img src={arvoloader} alt="Loading..." />
        </div>
      )}
      {!loading && (
        <div style={{ padding: "20px", textAlign: "center", backgroundColor: "#F2F7FF", height: "100vh", position: "relative" }}>
          {title ? <p style={{ fontWeight: '600', fontSize: "26px", color: '#0956cc' }}> Title: <span style={{ fontWeight: '400', fontSize: "22px", color: "black" }}>{title}</span></p> : <p>No product journey title found.</p>}
          {batchNumber && <p style={{ fontWeight: '600', fontSize: "22px", color: '#0956cc' }}> Batch Number: <span style={{ fontWeight: '400', fontSize: "18px", color: "black" }}>{batchNumber}</span></p>}
          
          {!capturedImage && (
            <>
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleCapture}
                style={{
                  display: "none"
                }}
                id="captureInput"
              />
              <label
                htmlFor="captureInput"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%)",
                  boxShadow: "4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset",
                  border: "none",
                  color: "white",
                  padding: "17px 25px",
                  cursor: "pointer",
                  fontSize: "20px",
                  fontWeight: "400",
                  borderRadius: "30px",
                  gap: "12px",
                  fontFamily: "Roboto",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "30%"
                }}
              >
                Capture
              </label>
            </>
          )}
          {capturedImage && (
            <>
              <img src={capturedImage} alt="Captured" style={{ width: "100px", height: "100px", marginBottom: "20px" }} />
              <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginBottom: "30px" }}>
                <button
                  onClick={handleRecapture}
                  style={{
                    background: "linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%)",
                    boxShadow: "4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset",
                    border: "none",
                    color: "white",
                    padding: "10px 20px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderRadius: "30px",
                    fontFamily: "Roboto"
                  }}
                >
                  Recapture
                </button>
                <button
                  onClick={handleRemove}
                  style={{
                    background: "linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%)",
                    boxShadow: "4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset",
                    border: "none",
                    color: "white",
                    padding: "10px 20px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderRadius: "30px",
                    fontFamily: "Roboto"
                  }}
                >
                  Remove Image
                </button>
              </div>
            </>
          )}

          <button
            onClick={handleButtonClick}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%)",
              boxShadow: "4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset",
              border: "none",
              color: "white",
              padding: "17px 25px",
              cursor: "pointer",
              fontSize: "20px",
              fontWeight: "400",
              borderRadius: "30px",
              gap: "12px",
              fontFamily: "Roboto",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "20%"
            }}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
}

export default QRReader;
